<template>
  <div class="bg_display_blue">
  <div id="bg" class="bg-antrian">
    <div id="main" class="flex">
      <div class="active flex" style="height:15%; margin-top: 0; margin-left: 0;">
        <div class="process flex" style="width: 100%; margin-right: 0;">
          <div class="header bg-white flex"
              style="height:100%; padding-left: 30px; padding-right: 30px; border-radius:0 !important;">
              <div class="loket-split flex" style="justify-content: left; width:30%; flex-direction: initial;">
              <div class="wrap_logo">
                <img :src="assetLocal('img/logo-rsh-wide.png')" />
              </div>
              </div>
              <div class="loket-split flex" style="width:60%;">
              <div class="date_time_wrap">
                  <h3 class="text-primary" style="font-size:3vh; text-align:center;">LAYANAN ANTRIAN RS HARAPAN MAGELANG</h3>
                  <p class="text-primary" style="margin:0;   text-align:center; font-size:1.8vh; padding-top:10px;">Jl. Panembahan Senopati
                  No. 11, Jurangombo Utara,<br />Kec. Magelang Sel., Kota Magelang, Jawa Tengah 56123</p>
              </div>
              </div>
              <div class="loket-split flex" style="width:30%;">
              <div class="date_time_wrap">
                  <p class="text-primary">{{ dates }}</p>
                  <h3 class="text-primary">{{ times }}</h3>
              </div>
              </div>
          </div>
          </div>
      </div>
      <!-- loket bawah / yang lagi aktif -->
      <div class="queue" style="height:80%;">

        
        <div class="row justify-content-center" style="margin-top:2%;">
          <div class="col-md-10">
            <div class="flex">
              <div class="wrap_queue_highlight wrap_scroll-2" style="display:block; padding:20px;">
                <div class="text_info">
                  <template v-if="!result">
                    <p>Cetak Surat Kontrol, Surat Istirahat, Surat Keterangan Sakit, dan Surat Keterangan Sehat dari kunjungan terakhir Anda di RS Harapan Magelang di sini. </p>
                  </template>
                  <template v-else>
                    <p><strong>Informasi Pasien</strong></p>
                    <p>{{pasien.ap_fullname}} - {{pasien.ap_code}}</p>
                  </template>
                </div>
                

                <div class="wrap_preview_checkin" style="max-width:80%;">
                  <template v-if="!result">
                    <input style="opacity: 0; height: 0; width: 0;" @input="readQr($event)" @blur="focusInput" type="text" v-model="row.kode" name="qrcode" id="qrCode">
                    <div v-if="!loading" class="warning_ci d-flex justify-content-center align-items-center">
                      <i class="icon-qrcode"></i>
                    </div>
                    <div v-else class="warning_ci d-flex justify-content-center align-items-center" style="background-color: #10d6ff;">
                      <span class="spinner spinner-border text-white"></span>
                    </div>
                    <div class="content_checkin">
                      <h3 v-if="loading" id="checkinTitle">Mohon tunggu beberapa saat</h3>
                      <h3 v-else id="checkinTitle">Scan QR Code Anda pada alat yang sudah disediakan</h3>
                    </div>

                    <template v-if="loading">
                      <div class="info_checkin" style="background-color:#00B52C;">
                        <p>Memuat QR Code...</p>
                      </div>
                    </template>
                    <template v-if="!loading && !found">
                      <div class="info_checkin" style="background-color:#ff1010;">
                        <p>Data tidak ditemukan. Pastikan QR Code telah sesuai</p>
                      </div>
                    </template>
                    <template v-else-if="!loading && !row.kode">
                      <div class="info_checkin" style="background-color:#ffa710;">
                        <p>Mohon cerahkan layar handphone ketika melakukan scan QR</p>
                      </div>
                    </template>
                  </template>

                  <template v-else-if="result">
                    <div class="text-center">
                      <h4 class="text-primary">Keluar Otomatis</h4>
                      <div class="row justify-content-center">
                        <div class="col-md-auto">
                            <div class="d-flex flex-row mb-3">
                              <div class=" wrap_count_down"><h3>{{ time.minutes }}</h3></div>
                              <div class=" wrap_count_down"><h3>:</h3></div>
                              <div class=" wrap_count_down"><h3>{{ time.seconds }}</h3></div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div class="cta_out">
                      <div class="row justify-content-end">
                        <div class="col-md-auto">
                          <button @click="reset" class="btn btn-labeled btn-labeled-left btn-danger">
                            <b> <i class="icon-exit mr-2"></i></b>
                            <span>Keluar Halaman</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive text-left mt-1 mb-1">
                      <table class="table table-bordered table-sm patient-table">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Nama Surat</th>
                            <th>Tanggal Kunjungan</th>
                            <th>Nama Dokter</th>
                            <th>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="result && (result || []).length < 1">
                            <tr>
                              <td colspan="5" class="text-center">Tidak terdapat surat untuk dicetak</td>
                            </tr>
                          </template>
                          <template v-else>
                            <tr v-for="(value, index) in result" :key="index">
                              <td>{{ index + 1 }}</td>
                              <td>{{ checkJenisSurat(value.jenis_surat) }}</td>
                              <td>{{ value.date | moment('DD MMMM YYYY, HH:mm') }}</td>
                              <td>{{ value.nama_dokter }}</td>
                              <td>
                                <button @click="print(value)" class="btn btn-labeled btn-labeled-left btn-info">
                                  <b> <i class="icon-printer mr-2"></i></b>
                                  <span>Cetak</span>
                                </button>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>

                    <iframe style="display: none;" id="pdf-frame" width="100%" height="600px"></iframe>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot flex">
        <marquee class="html-marquee" direction="left" behavior="scroll" scrollamount="12">
          <p>JAM BUKA LAYANAN KAMI ADALAH PUKUL 07:00 s.d 21.00. TERIMA KASIH ATAS KUNJUNGAN ANDA . KAMI SENANTIASA
            MELAYANI SEPENUH HATI</p>
        </marquee>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Gen from '@/libs/Gen.js'
import $ from 'jquery'
import _ from 'lodash'

export default {
  extends: GlobalVue,
  data() {
    return {
      times : null,
      dates : null,
      row: {},
      result: null,
      loading: false,
      found: true,
      pasien: {},

      timeLeft: 30, // in seconds
      intervalId: null,
      timerRunning: false
    }
  },
  computed: {
    // Format time in mm:ss format
    time() {
      let minutes = Math.floor(this.timeLeft / 60)
      let seconds = this.timeLeft % 60
      return {minutes: minutes.toString().padStart(2, '0'), seconds: seconds.toString().padStart(2, '0')}
    }
  },
  mounted(){
    setInterval(()=>{
      this.getTimeF()
    },500)

    let el = document.getElementById("qrCode")
    el.focus()

  },
  methods: {
    reset(){
      this.loading = false
      this.row.kode = ''
      this.result = null
      this.found = true
      this.focusInput()
      this.stopCountdown()
    },
    readQr: _.debounce( function(e) {
      if(!this.loading && !this.result && e.target.value.length < 16){
        this.loading = true
        this.row.kode = e.target.value

        setTimeout(() => {
          this.getSurat(this.row.kode)
        }, 1000)
      }
    }, 300),

    checkJenisSurat(slug){
      let jenisSurat = ''
      if(slug == 'surat-keterangan-sakit') jenisSurat = 'Surat Keterangan Sakit'
      else if(slug == 'surat-keterangan-sehat') jenisSurat = 'Surat Keterangan Sehat'
      else if(slug == 'surat-kontrol') jenisSurat = 'Surat Kontrol'
      return jenisSurat
    },

    getSurat(kode){
      Gen.apiRestDisplay("/displaySuratPasien?kode=" + kode)
      .then(res => {
        console.log(res)
        this.loading = false
        if(res.data.status == 'ERR') { 
          if(res.data.code == 'no_rm_not_valid') {
            this.found = false
          }
        }else {
          this.found = true
          this.pasien = res.data.pasien
          this.result = res.data.result
          this.startCountdown()
        }
      }).catch(err => {
        console.log(err)
        this.result = null
      })
    },

    focusInput(){
      setTimeout(function () { $("#qrCode").focus() }, 20)
    },
    getTimeF(){
      this.times = moment().format('HH:mm:ss')
      this.dates = moment().format('dddd, Do MMMM YYYY')
    },

    print(data){
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      if (data.jenis_surat == 'surat-kontrol') {
        let url = ''
        if(data.ar_mcp_id == 137 || data.ar_mcp_id == 138) url = 'report-kontrol-bpjs' 
        else url = 'report-kontrol' 
        $.ajax({
          type: 'GET',
          url: process.env.VUE_APP_API_URL + '/' + url + '?token=IXs1029102asoaksoas102901290&id=' + data.arrk_id,
          xhrFields: {
            responseType: 'blob'
          },
          success: data => {
            const url = window.URL.createObjectURL(data)
            document.getElementById('pdf-frame').setAttribute('src', url)

            setTimeout(() => {
              try {
                const pdfFrame = document.getElementById('pdf-frame').contentWindow
                pdfFrame.focus()
                pdfFrame.print()
                this.$swal.close()
                this.resetCountdown()
              } catch {
                this.$swal.close()
              }
            }, 200)
          }
        })
      } else {
        document.getElementById('pdf-frame').setAttribute('src', this.uploader(data.file))
        setTimeout(() => {
          try {
            const pdfFrame = document.getElementById('pdf-frame').contentWindow
            pdfFrame.focus()
            pdfFrame.print()
            this.$swal.close()
            this.resetCountdown()
          } catch {
            this.$swal.close()
          }

        }, 200)
      }
    },

    startCountdown() {
      this.timeLeft = 30  
      if (this.timerRunning) return // Prevent multiple intervals
      this.timerRunning = true
      this.intervalId = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--
        } else {
          this.reset()
          this.stopCountdown()
        }
      }, 1000)
    },

    stopCountdown() {
      clearInterval(this.intervalId)
      this.timerRunning = false
    },

    resetCountdown() {
      this.stopCountdown()
      this.startCountdown()
    }
  },
  beforeDestroy() {
    this.stopCountdown()
  }
}
</script>
<style lang="scss" scoped>
.spinner-border {
  min-width: 2rem;
  min-height: 2rem;
}
</style>